export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDLH-KYHyzSwFtC5nUph84eQq4UQUVYS-0",
        authDomain: "cix-employer-dev.firebaseapp.com",
        projectId: "cix-employer-dev",
        storageBucket: "cix-employer-dev.appspot.com",
        messagingSenderId: "905734144602",
        appId: "1:905734144602:web:5af952e6b679236cbd0e63"
    },
    apiEnvi: 'dev',
    brokerApiUrl: 'https://cixbrokeraffiliatemanageusers-dev.azurewebsites.net/api/',
    cixClientBaseUrl: 'https://cixapigateway-dev.azurewebsites.net',
};
